@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,600;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  color: #133092;
}

.outline input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  @apply transform  py-0 top-0 text-sm;
}

.outline div:focus-within ~ label,
label.has-value {
  @apply transform py-0 top-0 text-sm;
}

/* Loading Spinner */

.lds-ring {
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #133092;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #133092 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* END LOADING SPINNER */

/* PHONE INPUT */

.PhoneInput {
  @apply w-full h-full p-0 pl-2;
}

.PhoneInputInput {
  @apply w-full h-full pl-4 pt-4 pb-0 rounded-md text-base text-black m-0 border-2 border-gray-200;
}

.PhoneInputInput:focus-within {
  @apply border-2 border-blue-500;
}

/*  END PHONE INPUT  */

/* Remove blue ring find solution to overwrite it on the tailwind.config.js */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus {
  --tw-ring-color: none;
  --tw-ring-shadow: none;
}

/* END Remove blue ring */

/*    RICH TEXT   */
.quill {
  @apply bg-white rounded border border-gray-200;
}

.ql-toolbar.ql-snow {
  @apply flex justify-center items-center;
  @apply border-0 !important;
}

.ql-container {
  @apply border-0 !important;
}

.ql-editor {
  @apply min-h-64;
  @apply px-6 py-2 pt-0 text-black text-base border-0 !important;
}

@media only screen and (max-width: 678px) {
  .ql-editor {
    @apply text-sm px-4 !important;
    @apply max-h-32;
  }
}

.ql-editor * {
  color: black !important;
  font-family: "Public Sans", sans-serif;
}

.ql-snow.ql-toolbar button {
  @apply w-8 h-auto md:w-10 text-primary !important;
}

.ql-snow.ql-toolbar button svg {
  @apply text-primary !important;
}

.ql-toolbar.ql-snow .ql-formats {
  @apply mr-0 !important;
}
/*    END RICH TEXT     */

/*    CUSTOM COMPONET WITH HOVER ELEMENTS    */
.show-on-hover {
  display: none;
  opacity: 0;
}

.hover-over:hover div.show-on-hover {
  display: flex;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  opacity: 1;
}
/*    END    */

/*      WORKFLOW BUILDER      */
.react-flow {
  flex-direction: column;
  display: flex;
  height: 90vh !important;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside > * {
  margin-bottom: 10px;
  cursor: grab;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 180px;
  }
}
/*    END    */

/*      CHECKBOX DESIGN     */
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  width: 7px;
  height: 14px;
  border: solid #133092;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*        END               */
